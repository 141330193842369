import React from 'react';
import './Location.css';

function Location() {
  return (

    <section class="projects-section bg-light" id="projects">
        <div class='container'>
            <h1>Where We Purchase Mineral Rights</h1>
            <div>
                <img class="map img-fluid mb-3 mb-lg-0" src="/images/map.jpg" alt="" />
            </div>
        </div>
    </section>
  )
}

export default Location