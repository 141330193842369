import React from 'react';
import './Button.css';
import {Link} from 'react-router-dom';

const STYLES = ['btn--primary', 'btn--outline'];
const SIZES = ['btn--medium', 'btn--large'];

export const Button = ({children, type, onClick, buttonStyle, buttonSize}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];
    return(
        <div className='btn-wrapper'>
            <Link to='/WhySell' className='btn-mobile'>
                <button 
                    className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                    onClick={onClick}
                    type={type}
                >
                    {children}
                </button>
            </Link>  
        </div>
    )
}

export const SecondButton = ({children, type, onClick, buttonStyle, buttonSize}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    const scrollToBottom = () => {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
        }, 100);
    }
    return(
        <div className='btn-wrapper'>
            {/* <Link to='footer_link' className='btn-mobile'> */}
                <button 
                    className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                    onClick={scrollToBottom}
                    type={type}
                >
                    {children}
                </button>
            {/* </Link>   */}
        </div>
    )
    
}
