import React, {useState} from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import {useForm} from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import emailjs from '@emailjs/browser'

function Footer() {

    const scrollToTop = () => {
      window.scrollTo({
          top: 0,
          behavior: 'smooth'
      })
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  
    // Function that displays a success toast on bottom right of the page when form submission is successful
    const toastifySuccess = () => {
      toast('Form sent!', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        className: 'submit-feedback success',
        toastId: 'notifyToast',
        theme: "dark",
      });
    };
    
    // Function called on submit that uses emailjs to send email of valid contact form
    const onSubmit = async (data) => {
      const { name, email, phone, message, message2 } = data;
      try {
        const templateParams = {
          name,
          email,
          phone,
          message,
          message2
        };
  
        await emailjs.send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          templateParams,
          process.env.REACT_APP_PUBLIC_KEY
        );
  
        reset();
        toastifySuccess();
      } catch (e) {
        console.log(e);
        }
    };

  return (
    <div className='footer-container'>
      <div className='ContactForm'>
      <div className='contact-container'>
        <h1>Contact Form</h1>
        {/* <h4>We'd love to answer any questions</h4> */}
        <div className='contact-row'>
          <div className='col-12 text-center'>
            <div className='contactForm'>
              <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                {/* Row 1 of form */}
                <div className='row formRow'>
                  <div className='col-6 col-name'>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Please enter your name' },
                        maxLength: {
                          value: 30,
                          message: 'Please use 30 characters or less'
                        }
                      })}
                      className='form-control formInput'
                      placeholder='Name'
                    ></input>
                    {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                  </div>
                </div>
                {/* Row 2 of form */}
                <div className='row formRow'>
                <div className='col col-email'>
                    <input
                      type='email'
                      name='email'
                      {...register('email', {
                        required: true,
                        pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                      })}
                      className='form-control formInput'
                      placeholder='Email address'
                    ></input>
                    {errors.email && (
                      <span className='errorMessage'>Please enter a valid email address</span>
                    )}
                  </div>
                  <div className='col col-phone'>
                    <input
                      type='text'
                      name='phone'
                      {...register('phone', {
                        required: { value: true, message: 'Please enter a Phone Number' },
                        maxLength: {
                          value: 75,
                          message: 'Phone Number cannot exceed 75 characters'
                        }
                      })}
                      className='form-control formInput'
                      placeholder='Phone Number'
                    ></input>
                    {errors.phone && (
                      <span className='errorMessage'>{errors.phone.message}</span>
                    )}
                  </div>
                </div>
                {/* Row 3 of form */}
                <div className='row formRow'>
                  <div className='col-6 col-message'>
                    <textarea
                      rows={1}
                      name='message'
                      {...register('message', {
                        required: true
                      })}
                      className='form-control formInput'
                      placeholder='Property Location(Tax Map ID,Parcel ID)'
                    ></textarea>
                    {errors.message && <span className='errorMessage'>Please enter a message</span>}
                  </div>
                </div>
                {/* Row 4 of form */}
                <div className='row formRow'>
                  <div className='col-6 col-message'>
                    <textarea
                      rows={2}
                      name='message2'
                      {...register('message2', {
                        required: true
                      })}
                      className='form-control formInput'
                      placeholder='Property Description(Well Name, Section, Township, Range, Abstract, Survey, County, State)'
                    ></textarea>
                    {errors.message && <span className='errorMessage'>Please enter a message</span>}
                  </div>
                </div>
                <button className='btn--contact' type='submit'>
                  Send Message
                </button>
                {/* {button && <Button buttonStyle='btn--outline'>SUBMIT</Button>} */}
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="direct-contact-container">
        <ul className="contact-list">
          <li className="list-item"><i className="fa fa-map-marker fa-2x"><span className="contact-text place">P.O. BOX 5479, Norman, OK 73070</span></i></li>
          <li className="list-item"><i className="fa fa-phone fa-2x"><span className="contact-text phone"><a href="tel:1-405-795-2877" title="Give me a call">(405) 795-2877</a></span></i></li>
          <li className="list-item"><i className="fa fa-envelope fa-2x"><span className="contact-text gmail"><a href="mailto:tqminerals@outlook.com" title="Send me an email">tqminerals@outlook.com</a></span></i></li>
        </ul>
      </div>
    </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo' onClick={()=>{
                                scrollToTop();
                                }}>
              <img  src='/images/logo2.png' alt='TQ MINERALS Logo'/> TQ MINERALS
            </Link>
          </div>
          <small className='website-rights'>TQ MINERALS © 2023</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;