import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
// import { Button } from './Button';
import { SecondButton } from './Button';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
    const scrollToBottom = () => {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
        }, 100);
    }

    // media query - retoggle/resizes button
    const showButton = () => {
        if(window.innerWidth <= 960){
            setButton(false);
        }else {
            setButton(true);
        }
    }

    // sign up button reappears in mobile on refresh
    // add hook useEffect - renders 1 time and not show up again
    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav className="navbar">
                <div className="navbar-container">
                    {/* click navbar logo will close mobile menu */}
                    <Link to='/' className='navbar-logo' onClick={()=>{
                                scrollToTop();
                                closeMobileMenu();}}>
                    <img  src='/images/logo2.png' alt='TQ MINERALS Logo'/> TQ MINERALS
                    </Link>
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={()=>{
                                scrollToTop();
                                closeMobileMenu();}}>
                                Home
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/whysell' className='nav-links' onClick={()=>{
                                closeMobileMenu();
                                scrollToTop();
                                }}>
                                Why Sell?
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/locations' className='nav-links' onClick={()=>{
                                closeMobileMenu();
                                scrollToTop();
                                }}>
                                Locations
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/' className='nav-links-mobile' onClick={()=>{
                                closeMobileMenu();
                                scrollToBottom();
                                }}>
                                Get Started
                            </Link>
                        </li>
                    </ul>
                    {button && 
                        <a href='#footer_link'>
                            <SecondButton buttonStyle='btn--outline'>Get Started</SecondButton>
                        </a>}
                </div>
            </nav>
        </>
    )
}

export default Navbar