import React from 'react';
import './AboutMe.css';

function AboutMe() {
  return (

    <section class="projects-section bg-light" id="projects">
        <div class="container px-4 px-lg-5">
            
            <div class="row gx-0 mb-4 mb-lg-5 align-items-center">
                <div class="col-xl-8 col-lg-7"><img class="img-fluidAM mb-3 mb-lg-0" src="/images/family2.jpg" alt="Mark with wife and two daughters" /></div>
                <div class="col-xl-4 col-lg-5">
                    <div class="featured-text text-center text-lg-left">
                        <h1>Mark Miller II</h1>
                        <p class="text-black-aboutme mb-0">With thirteen years of both in-house and field land experience, Mark Miller is a seasoned energy professional with experience in prospect management, due diligence, farmout agreements, purchase and sale agreements, and joint ventures. Prior to starting TQ he served as Director of Land at Far West Development, LLC, where he provided land management, leasing, title examination, due diligence, and data management for the Mid-Continent SCOOP/STACK plays in the Anadarko Basin. Mark's expertise extends to both developed and undeveloped areas within Oklahoma, Texas and various oil and gas plays in the lower 48. His ability to lead and manage multiple projects at once has been proven, and he has effectively guided his team members towards a common goal. In addition, Mark is an Army Veteran and graduate from the University of Oklahoma.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default AboutMe