import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection.js';
import AboutMe from '../AboutMe';
import Footer from '../Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

function Home () {
    return (
        <>
            <HeroSection />
            <AboutMe />
            <Footer />
            <ToastContainer />
        </>
    )
}

export default Home;