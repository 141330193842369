import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import WhySell from './components/pages/WhySell';
import Locations from './components/pages/Locations';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/WhySell' element={<WhySell/>} />
          <Route path='/Locations' element={<Locations/>} />
          {/* <Route path='/sign-up' element={<SignUp/>} /> */}
        </Routes>
      </Router>
    </>    
  );
}

export default App;