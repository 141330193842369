import React from 'react';
import '../../App.css';
import SellContent from '../SellContent';
import Footer from '../Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

function WhySell () {
    return (
        <>
            <SellContent />
            <Footer />
            <ToastContainer />
        </>
    )
}

export default WhySell;