import React from 'react';
import '../App.css';
import { Button } from './Button';
import { SecondButton } from './Button';
import './HeroSection.css';
// import Video from './videos/video3.mp4';

import { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import {ScrollTrigger } from 'gsap/ScrollTrigger';

function HeroSection() {
    gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  useEffect(() => {
    const element = ref.current;
    gsap.fromTo(
      element.querySelector('.animate-hero'),
      {
        duration: 0,
        opacity: 20,
        y: -150,
        stagger: 1
      },
      {
        opacity: 100,
        y: 0,
        scale: 1,
        duration: .6,
        ease: "none",
      }
    )
  })  

  return (
    <div className='hero-container' ref={ref}>
        {/* <video src={Video} autoPlay loop muted /> */}
        <h1 className='animate-hero'>TQ MINERALS</h1>
        <p>Unlock the Value Beneath Your Land</p>
        <div className="hero-btns">
            <SecondButton 
                className='btns btn-mobile2'
                buttonStyle='btn--primary'
                buttonSize='btn--large'
            >
                GET STARTED    
            </SecondButton>
            <Button 
                className='btns btn-mobile' 
                buttonStyle='btn--primary'
                buttonSize='btn--large'
            >
                WHY SELL?     
            </Button>
        </div>
    </div>
  )
}

export default HeroSection;