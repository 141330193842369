import React from 'react';
import './SellContent.css';

function SellContent() {
  return (

    <section className="projects-section bg-light" id="projects">
        <div className="container px-4 px-lg-5">
            
            <div className="row gx-0 mb-4 mb-lg-5 align-items-center">
                <div className="col-xl-4 col-lg-5">
                    <div className="featured-text text-left text-lg-left">
                        <h4>Why Sell</h4>
                        <ul className="text-black-50 mb-0">
                            <li>&bull; Quick evaluations for immediate financial gains</li>
                            <li>&bull; Eliminate risks & responsibilities</li>
                            <li>&bull; Uncertainty in future production</li>
                            <li>&bull; Simplifying estate planning</li>
                            <li>&bull; Diversification of assets</li>
                        </ul>
                    </div>
                </div>
                <div className="col-xl-8 col-lg-7"><img className="img-fluid mb-3 mb-lg-0" src="/images/pic2.jpg" alt="" /></div>

            </div>
        </div>
    </section>
  )
}

export default SellContent